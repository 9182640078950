import { render, staticRenderFns } from "./PromotionFilterActivationsLimit.vue?vue&type=template&id=dc8f15d0&"
import script from "./PromotionFilterActivationsLimit.vue?vue&type=script&lang=js&"
export * from "./PromotionFilterActivationsLimit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
installComponents(component, {VAvatar,VIcon,VListItem,VListItemAvatar,VListItemContent})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/cplug-fid-front-customer/cplug-fid-front-customer/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('dc8f15d0')) {
      api.createRecord('dc8f15d0', component.options)
    } else {
      api.reload('dc8f15d0', component.options)
    }
    module.hot.accept("./PromotionFilterActivationsLimit.vue?vue&type=template&id=dc8f15d0&", function () {
      api.rerender('dc8f15d0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/promotion/trigger/filters/PromotionFilterActivationsLimit.vue"
export default component.exports
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-breadcrumbs",
            {
              staticClass: "pb-1 px-3",
              class: _vm.isLightTheme ? "breadcrumb-item" : "",
              attrs: { items: _vm.breadcrumbs }
            },
            [
              _c(
                "v-icon",
                {
                  staticClass: "breadcrumbs-chevro",
                  attrs: { slot: "divider" },
                  slot: "divider"
                },
                [_vm._v("fas fa-chevron-right")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "background", attrs: { outlined: "" } },
        [
          _c(
            "v-card-text",
            { staticClass: "px-3 pt-4" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pa-0", attrs: { cols: "12", md: "12" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "section-title font-weight-bold primary_font--text"
                        },
                        [_vm._v(_vm._s(_vm.computedTitle))]
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.promotion
    ? _c(
        "v-card",
        _vm._g(
          _vm._b({ attrs: { elevation: "0" } }, "v-card", _vm.attrs, false),
          _vm.listeners
        ),
        [
          _c("v-card-title", { attrs: { "primary-title": "" } }, [
            _c("div", [
              _c(
                "div",
                { staticClass: "title primary--text font-weight-bold" },
                [_vm._v(_vm._s(_vm.promotion.name))]
              ),
              _c("div", { staticClass: "subtitle-2 secondary_font--text" }, [
                _vm._v(_vm._s(_vm.promotion.getRangeLabel()))
              ])
            ])
          ]),
          _c(
            "v-card-text",
            [
              _vm.promotion.photo_url
                ? _c("v-img", {
                    staticClass: "mb-4",
                    attrs: {
                      src: _vm.promotion.photo_url,
                      "max-height": "200",
                      contain: ""
                    }
                  })
                : _vm._e(),
              _c("p", [_vm._v(_vm._s(_vm.promotion.description))]),
              !_vm.filters.length
                ? [
                    _vm.actions.length
                      ? [
                          _c(
                            "div",
                            { staticClass: "subtitle-1 font-weight-bold" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "views.TriggerPromotionView.actions_title"
                                  )
                                )
                              )
                            ]
                          ),
                          _c(
                            "v-list",
                            _vm._l(_vm.actions, function(action, index) {
                              return _c(action.getListItemComponent(), {
                                key: index,
                                tag: "component",
                                attrs: { action: action }
                              })
                            }),
                            1
                          )
                        ]
                      : _vm._e()
                  ]
                : [
                    _c("div", { staticClass: "subtitle-1 font-weight-bold" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("views.TriggerPromotionView.filters_title")
                        )
                      )
                    ]),
                    _c(
                      "v-list",
                      _vm._l(_vm.filters, function(filter, index) {
                        return _c(filter.getListItemComponent(), {
                          key: index,
                          tag: "component",
                          attrs: { filter: filter }
                        })
                      }),
                      1
                    )
                  ]
            ],
            2
          ),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  class: _vm.isLightTheme ? "primary_font--text" : "",
                  attrs: {
                    block: "",
                    color: "",
                    elevation: "0",
                    to: { name: "home" }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("actions.back")))]
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list-item",
    [
      _c(
        "v-list-item-avatar",
        [
          _c("v-icon", { attrs: { color: "success" } }, [
            _vm._v("$cp_generate_point")
          ])
        ],
        1
      ),
      _c("v-list-item-content", [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t(
                "models.PromotionAction.type.GENERATE_POINTS.list_item_label",
                [_vm.dynamicValue]
              )
            ) +
            " "
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c("v-progress-circular", {
        staticClass: "mb-3",
        attrs: { indeterminate: "", color: "primary" }
      }),
      _c("div", [
        _c("small", [
          _vm._v(_vm._s(_vm.$t("views.TriggerPromotionView.loading")))
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <v-card elevation="0" v-if="promotion" v-bind="attrs" v-on="listeners">
        <v-card-title primary-title>
            <div>
                <div class="title primary--text font-weight-bold">{{ promotion.name }}</div>
                <div class="subtitle-2 secondary_font--text">{{ promotion.getRangeLabel() }}</div>
                <!-- <div class="section-title font-weight-bold primary_font--text">{{ user.name ? user.name : '' }}</div> -->
            </div>
        </v-card-title>
        <v-card-text>
            <!-- Imagem e descrição -->
            <v-img v-if="promotion.photo_url" :src="promotion.photo_url" max-height="200" contain class="mb-4"></v-img>
            <p>{{ promotion.description }}</p>

            <!-- Lista ações que foram tomadas a partir desta promoção -->
            <template v-if="!filters.length">
                <template v-if="actions.length">
                    <div class="subtitle-1 font-weight-bold">{{ $t('views.TriggerPromotionView.actions_title') }}</div>

                    <v-list>
                        <component
                            v-for="(action, index) in actions"
                            :key="index"
                            :is="action.getListItemComponent()"
                            :action="action"
                        />
                    </v-list>
                </template>
            </template>

            <!-- Se a promoção foi filtrada, mostra os motivos -->
            <template v-else>
                <div class="subtitle-1 font-weight-bold">{{ $t('views.TriggerPromotionView.filters_title') }}</div>

                <v-list>
                    <component
                        v-for="(filter, index) in filters"
                        :key="index"
                        :is="filter.getListItemComponent()"
                        :filter="filter"
                    />
                </v-list>
            </template>

        </v-card-text>
        <v-card-actions>
            <v-btn
                block color="" elevation="0"
                :to="{ name: 'home' }"
                :class="isLightTheme ? 'primary_font--text': ''"
            >{{ $t('actions.back') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import Promotion from '@/models/Promotion'
import PromotionAction from '@/models/PromotionAction'
import PromotionFilter from '@/models/PromotionFilter'
import RateColor from '@/shared/mixins/RateColor'

export default {
    name: 'TriggerPromotionViewContent',
    props: {
        response : Object,
    },
    mixins: [ RateColor ],
    computed: {
        promotion() {
            let promotion = this.$lodash.get(this.response, 'data.promotion')
            return promotion ? new Promotion(promotion) : null
        },
        actions() {
            let actions = this.$lodash.get(this.response, 'data.actions', [])
            return PromotionAction.hydrate(actions)
        },
        filters() {
            let filters = this.$lodash.get(this.response, 'data.filters', [])
            return PromotionFilter.hydrate(filters)
        },

        /**
         * Extrai todos os listeners deste componente, exceto os que estamos sobrescrevendo
         */
        listeners() {
            const { input, ...listeners } = this.$listeners
            return listeners
        },

        /**
         * Extrai todas as props deste componente, exceto as que estamos sobrescrevendo
         */
        attrs() {
            const { value, ...attrs } = this.$attrs
            return { ...attrs }
        },
    },
}
</script>
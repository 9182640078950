<template>
    <v-container>
        <TriggerPromotionViewHeader :response="response"/>
        <v-row>
            <v-col>
                <div class="d-flex justify-center">
                    <TriggerPromotionViewLoader v-if="loading"/>
                    <TriggerPromotionViewContent v-else-if="!hasError && response" width="100%" :max-width="600" :response="response"/>
                    <TriggerPromotionViewSearchCard ref="search_card" width="100%" :max-width="600" v-if="!promotionCode || hasError"/>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import TriggerPromotionViewHeader from './TriggerPromotionViewHeader'
import TriggerPromotionViewLoader from './TriggerPromotionViewLoader'
import TriggerPromotionViewContent from './TriggerPromotionViewContent'
import TriggerPromotionViewSearchCard from './TriggerPromotionViewSearchCard'
import Promotion from '@/models/Promotion'
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'

export default {
    name: 'TriggerPromotionView',
    components: {
        TriggerPromotionViewHeader,
        TriggerPromotionViewLoader,
        TriggerPromotionViewContent,
        TriggerPromotionViewSearchCard,
    },
    mixins: [ HasErrorHandlerMixin ],
    data: vm => ({
        loading  : false,
        error    : false,
        response : null,
    }),
    methods: {
        async fetchPromotionByCode() {
            this.response  = null
            this.error     = null

            if (!this.promotionCode)
                return

            this.loading   = true

            this.response  = await Promotion.triggerByCode(this.promotionCode)
                // catch tá assim para não travar no async
                .catch((e) => { this.preErrorHandler(e) })
            this.loading = false

            if (!this.response)
                return
        },
        async preErrorHandler(error) {
            this.error = error
            
            // Verifica se é algum erro conhecido que precise de tratamento especial
            // será tratado no componente de erro
            let status         = this.$lodash.get(error, 'response.status')
            let errorCode      = this.$lodash.get(error, 'response.data.code')
            let expectedErrors = [404, 422]
            let dynamicMessage = status ? this.$t('views.TriggerPromotionView.errors.generic') : null
            if (expectedErrors.includes(status)) {
                const map = {
                    404                   : this.$t('views.TriggerPromotionView.errors.404', { promotion_code: this.promotionCode }),
                    422                   : this.$t('views.TriggerPromotionView.errors.422'),
                    'promotion-not-found' : this.$t('views.TriggerPromotionView.errors.404', { promotion_code: this.promotionCode }),
                    'customer-not-found'  : this.$t('views.TriggerPromotionView.errors.404', { promotion_code: this.promotionCode }),
                    'promotion-not-active': this.$t('views.TriggerPromotionView.errors.inactive'),
                }
                dynamicMessage = map[errorCode || status]
            }

            if (dynamicMessage) {
                await new Promise((resolve) => {
                    this.$bus.$emit('alert', dynamicMessage, 'error', resolve)
                })

                // Volta o foco para o input
                this.$nextTick(() => {
                    this.$refs.search_card && this.$refs.search_card.focus()
                })

                return
            }

            this.errorHandler(error)
        },
    },
    computed: {
        promotionCode() {
            return this.$route.params.promotion_code
        },
        hasError() {
            return !this.$lodash.isEmpty(this.error)
        },
    },
    watch: {
        '$route.params': {
            deep: true,
            immediate: true,
            handler() {
                this.fetchPromotionByCode()
            },
        },
    },
}
</script>
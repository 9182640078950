import SharedPromotionFilter from '@/shared/models/PromotionFilter'
import $constants from '@/services/constants'
import PromotionFilterNewRegister from '@/views/promotion/trigger/filters/PromotionFilterNewRegister'
import PromotionFilterActivationsLimit from '@/views/promotion/trigger/filters/PromotionFilterActivationsLimit'
import PromotionFilterCompleteProfile from '@/views/promotion/trigger/filters/PromotionFilterCompleteProfile'

export default class PromotionFilter extends SharedPromotionFilter {
    
    defaults() {
        return {
            ...super.defaults(),
        }
    }

    getListItemComponent() {
        return ({
            [$constants.getConstant('PROMOTION_FILTER_TYPE_NEW_REGISTER')]     : PromotionFilterNewRegister,
            [$constants.getConstant('PROMOTION_FILTER_TYPE_ACTIVATIONS_LIMIT')]: PromotionFilterActivationsLimit,
            [$constants.getConstant('PROMOTION_FILTER_TYPE_COMPLETE_PROFILE')] : PromotionFilterCompleteProfile,
        })[this.type]
    }

}
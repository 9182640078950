import SharedPromotionAction from '@/shared/models/PromotionAction'
import $constants from '@/services/constants'
import PromotionActionPointsGenerate from '@/views/promotion/trigger/actions/PromotionActionPointsGenerate'

export default class PromotionAction extends SharedPromotionAction {
    defaults() {
        return {
            ...super.defaults(),
            result: {},
        }
    }

    getListItemComponent() {
        return ({
            [$constants.getConstant('PROMOTION_ACTION_TYPE_GENERATE_POINTS')]: PromotionActionPointsGenerate,
        })[this.type]
    }

}
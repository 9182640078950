var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("TriggerPromotionViewHeader", { attrs: { response: _vm.response } }),
      _c(
        "v-row",
        [
          _c("v-col", [
            _c(
              "div",
              { staticClass: "d-flex justify-center" },
              [
                _vm.loading
                  ? _c("TriggerPromotionViewLoader")
                  : !_vm.hasError && _vm.response
                  ? _c("TriggerPromotionViewContent", {
                      attrs: {
                        width: "100%",
                        "max-width": 600,
                        response: _vm.response
                      }
                    })
                  : _vm._e(),
                !_vm.promotionCode || _vm.hasError
                  ? _c("TriggerPromotionViewSearchCard", {
                      ref: "search_card",
                      attrs: { width: "100%", "max-width": 600 }
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="text-center">
        <v-progress-circular indeterminate color="primary" class="mb-3"/>
        <div>
            <small>{{ $t('views.TriggerPromotionView.loading') }}</small>
        </div>
    </div>
</template>
<script>
export default {
    name: 'TriggerPromotionViewLoader',
}
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list-item",
    [
      _c(
        "v-list-item-avatar",
        [
          _c(
            "v-avatar",
            { attrs: { color: "error", icon: "" } },
            [
              _c("v-icon", { attrs: { small: "", color: "white" } }, [
                _vm._v("fas fa-filter")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("v-list-item-content", [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t(
                "models.PromotionFilter.type.ACTIVATIONS_LIMIT.list_item_label"
              )
            ) +
            " "
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div>
        <v-row>
            <v-breadcrumbs :class="isLightTheme ? 'breadcrumb-item': ''" class="pb-1 px-3" :items="breadcrumbs">
                <v-icon slot="divider" class="breadcrumbs-chevro">fas fa-chevron-right</v-icon>
            </v-breadcrumbs>
        </v-row>
        <v-card class="background" outlined>
            <v-card-text class="px-3 pt-4">
                <v-row>
                    <v-col cols="12" md="12" class="pa-0">    
                        <!-- <div class="subtitle-2 secondary_font--text mb-1">{{ $t('views.TriggerPromotionView.subtitle') }}</div> -->
                        <div class="section-title font-weight-bold primary_font--text">{{ computedTitle }}</div>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
import RateColor from '@/shared/mixins/RateColor'

export default {
    name: 'TriggerPromotionViewHeader',
    mixins: [ RateColor ],
    props: {
        response: Object,
    },
    data: vm => ({
        breadcrumbs: [
            { text: vm.$t('drawer.home'), to: { name: 'home' } },
            { text: vm.$t('drawer.promotion'), to: { name: 'promotion' } },
            { text: vm.$t('drawer.trigger_promotion'), disabled: true },
        ],
    }),
    computed: {
        hasActions() {
            let actions = this.$lodash.get(this.response, 'data.actions', [])
            return !!actions.length
        },
        computedTitle() {
            return this.hasActions ? this.$t('views.TriggerPromotionView.title_triggered') : this.$t('views.TriggerPromotionView.title')
        },
    },
}
</script>
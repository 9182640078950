<template>
    <v-card elevation="0" v-bind="attrs">
        <v-card-title class="primary--text title font-weight-bold">{{ $t('views.TriggerPromotionView.search_by_code.title') }}</v-card-title>
        <v-card-text>
            <p>
                {{ $t('views.TriggerPromotionView.search_by_code.description') }}
            </p>

            <v-form ref="form" v-model="isFormValid" @submit.prevent="submit">
                <cp-text-field
                    ref="search_by_code"
                    v-model="promotion_code"
                    :label="$t('views.TriggerPromotionView.search_by_code.label')"
                    :placeholder="$t('views.TriggerPromotionView.search_by_code.placeholder')"
                    :hint="$t('views.TriggerPromotionView.search_by_code.hint')"
                    solo @keyup.enter="submit" autofocus
                    :rules="rules"
                />
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-row>
                <v-col class="py-0 pr-sm-1 mb-3 mb-sm-0" cols="12" sm="6">
                    <v-btn
                        block color="" elevation="0"
                        :to="{ name: 'home' }"
                        :class="isLightTheme ? 'primary_font--text': ''"
                    >{{ $t('actions.back') }}</v-btn>
                </v-col>
                <v-col class="py-0 pl-sm-1" cols="12" sm="6">
                    <v-btn
                        block color="primary" elevation="0"
                        @click="submit"
                        :class="isLightTheme ? 'primary_font--text': ''"
                        :disabled="isSearchDisabled"
                    >{{ $t('actions.search') }}</v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>
</template>
<script>
import RateColor from '@/shared/mixins/RateColor'
import { required, length, string, match, not } from '@/utils/validation'

export default {
    name: 'TriggerPromotionViewSearchCard',
    mixins: [ RateColor ],
    inheritAttrs: false,
    data: vm => ({
        promotion_code: vm.$route.params.promotion_code,
        isFormValid   : true,
        rules         : [required.and(string).and(length(0, 50)).and(match(/^[a-zA-Z0-9_-]*$/)).and(not(vm.$route.params.promotion_code))],
    }),
    methods: {
        submit() {
            if (!this.isFormValid)
                return

            this.$router.push({ name: 'promotion.trigger', params: { promotion_code: this.promotion_code } })
        },
        focus() {
            this.$refs.search_by_code && this.$refs.search_by_code.focus()
        },
    },
    computed: {
        /**
         * Extrai todas as props deste componente, exceto as que estamos sobrescrevendo
         */
        attrs() {
            const { value, ...attrs } = this.$attrs
            return { ...attrs }
        },

        isSearchDisabled() {
            return !this.isFormValid
            // return !this.isFormValid || this.promotion_code == this.$route.params.promotion_code
        },
    },
}
</script>
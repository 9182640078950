<template>
    <v-list-item>
        <v-list-item-avatar>
            <v-icon color="success">$cp_generate_point</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
            {{ $t('models.PromotionAction.type.GENERATE_POINTS.list_item_label', [dynamicValue]) }}
        </v-list-item-content>
    </v-list-item>
</template>
<script>
import PromotionAction from '@/models/PromotionAction'
import Generator from '@/models/Generator'

export default {
    name: 'PromotionActionPointsGenerate',
    props: {
        action: PromotionAction,
    },
    computed: {
        dynamicValue() {
            let generator = this.$lodash.get(this.action, 'result.generator')

            if (!generator)
                return ''

            generator = new Generator(generator)

            return generator.getDynamicValueLabel({ preventPercentage: true })
        },
    },
}
</script>
<template>
    <v-list-item>
        <v-list-item-avatar>
            <v-avatar color="error" icon>
                <v-icon small color="white">fas fa-filter</v-icon>
            </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
            {{ $t('models.PromotionFilter.type.ACTIVATIONS_LIMIT.list_item_label') }}
        </v-list-item-content>
    </v-list-item>
</template>
<script>
import PromotionFilter from '@/models/PromotionFilter'

export default {
    name: 'PromotionFilterNewRegister',
    props: {
        filter: PromotionFilter,
    },
}
</script>